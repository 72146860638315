import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: []
};

const reducers = {
  setCountries: (state, action) => {
    state.countries = action.payload;
  },
  addCountry: (state, action) => {
    state.countries = [action.payload, ...state.countries];
  },
  deleteCountry: (state, action) => {
    const countries = [...state.countries];
    state.countries = [...countries.filter(country => country.id !== action.payload.id)];
  },
  updateCountry: (state, action) => {
    const countries = [...state.countries];
    countries[countries.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.countries = countries;
  }
};

const countriesManagementSlice = createSlice({
  name: 'countriesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = countriesManagementSlice;

// ACTIONS
export const {
  setCountries,
  addCountry,
  deleteCountry,
  updateCountry
} = actions;

// GETTERS
export const selectCountriesManagementState = (state) => state.countriesManagement;

export default reducer;
