import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  forms: [],
  editedForm: []
};

const reducers = {
  setEditedForm: (state, action) => {
    state.editedForm = action.payload;
  },
  cleanEditedForm: (state) => {
    state.editedForm = [];
  },
  setForms: (state, action) => {
    state.forms = action.payload;
  },
  addForm: (state, action) => {
    state.forms = [action.payload, ...state.forms];
  },
  removeForm: (state, action) => {

  },
  updateForm: (state, action) => {

  },
  deleteForms: (state, action) => {
    const forms = [...state.forms];
    state.forms = [...forms.filter(item => item.id !== action.payload.id)];
  }
};

const formsManagementSlice = createSlice({
  name: 'formsManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = formsManagementSlice;

// ACTIONS
export const {
  setForms,
  addForm,
  removeNovelty,
  updateNovelty,
  deleteForms,
  setEditedForm,
  cleanEditedForm
} = actions;

// GETTERS
export const selectFormsManagementState = (state) => state.formsManagement;

export default reducer;
