import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  novelties: [],
  novelty: []
};

const reducers = {
  setNovelties: (state, action) => {
    state.novelties = action.payload;
  },
  setNovelty: (state, action) => {
    state.novelty = action.payload;
  },
  addNovelty: (state, action) => {
    state.novelties = [action.payload, ...state.novelties];
  },
  addNoveltyService: (state, action) => {
    state.novelty = [action.payload.data, ...state.novelty];
  },
  deleteNovelty: (state, action) => {
    const novelties = [...state.novelties];
    state.novelties = [...novelties.filter(item => item.id !== action.payload.id)];
  },
  updateNovelty: (state, action) => {
    const novelties = [...state.novelties];
    novelties[novelties.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.novelties = novelties;
  }
};

const noveltiesManagementSlice = createSlice({
  name: 'noveltiesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = noveltiesManagementSlice;

// ACTIONS
export const {
  setNovelties,
  setNovelty,
  addNovelty,
  updateNovelty,
  deleteNovelty,
  addNoveltyService
} = actions;

// GETTERS
export const selectNoveltiesManagementState = (state) => state.noveltiesManagement;

export default reducer;
