import { React, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import './svg-filter-advanced.scss';

export function SvgFilterAdvanced (props) {
  const { parameterFilterAdvanced, onFilterAdvanced } = props;
  const [dialogVisible, setDialogVisible] = useState(false);
  const [valueComparerText, setValueComparerText] = useState('');
  const [value1Comparer, setValue1Comparer] = useState(0);
  const [value2Comparer, setValue2Comparer] = useState(1);
  const [columnSelected, setColumnSelected] = useState(null);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);

  const comparerSourcesBase = [
    { name: 'Entre', code: 'entre', types: ['number', 'datetime'] },
    { name: 'Mayor que', code: 'mayor', types: ['number', 'datetime'] },
    { name: 'Menor que', code: 'menor', types: ['number', 'datetime'] },
    { name: 'Mayor o igual que', code: 'mayorIgual', types: ['number', 'datetime'] },
    { name: 'Menor o igual que', code: 'menorIgual', types: ['number', 'datetime'] },
    { name: 'Contains', code: 'contains', types: ['string', 'number'] }
  ];

  const [comparerSelected, setComparerSelected] = useState(null);
  const [comparerSources, setComparerSources] = useState([]);

  const addNewFilter = () => {
    if (!validate()) return false;

    if (columnSelected.type === 'number') {
      if (columnSelected.code === 'entre') {
        const newFilter = {
          categoryValue: columnSelected.categoryValue,
          categoryDisplay: columnSelected.categoryDisplay,
          display: `Entre ${value1Comparer} y ${value2Comparer}`,
          values: [value1Comparer, value2Comparer],
          comparer: comparerSelected.code,
          type: comparerSelected.type
        };
        onFilterAdvanced(newFilter);
        setDialogVisible(false);
        return;
      }

      const newFilter = {
        categoryValue: columnSelected.categoryValue,
        categoryDisplay: columnSelected.categoryDisplay,
        display: `${comparerSelected.name} ${value1Comparer}`,
        values: [value1Comparer],
        comparer: comparerSelected.code,
        type: comparerSelected.type
      };
      onFilterAdvanced(newFilter);
      setDialogVisible(false);
      return;
    }

    if (columnSelected.type === 'string') {
      const newFilter = {
        categoryValue: columnSelected.categoryValue,
        categoryDisplay: columnSelected.categoryDisplay,
        display: `${comparerSelected.name} ${valueComparerText}`,
        values: [valueComparerText],
        comparer: comparerSelected.code,
        type: comparerSelected.type
      };
      onFilterAdvanced(newFilter);
      setDialogVisible(false);
      return;
    }

    if (columnSelected.type === 'datetime') {
      if (columnSelected.code === 'entre') {
        const invertTime = moment(date1) < moment(date2);
        const fecha1 = moment(invertTime ? date1 : date2).format('YYYY-MM-DD HH:mm');
        const fecha2 = moment(invertTime ? date2 : date1).format('YYYY-MM-DD HH:mm');
        const newFilter = {
          categoryValue: columnSelected.categoryValue,
          categoryDisplay: columnSelected.categoryDisplay,
          display: `Entre ${fecha1} y ${fecha2}`,
          values: [fecha1, fecha2],
          comparer: comparerSelected.code,
          type: comparerSelected.type
        };
        onFilterAdvanced(newFilter);
        setDialogVisible(false);
        return;
      }
      const fecha1 = moment(date1).format('YYYY-MM-DD HH:mm');
      const newFilter = {
        categoryValue: columnSelected.categoryValue,
        categoryDisplay: columnSelected.categoryDisplay,
        display: `${comparerSelected.name} ${fecha1}`,
        values: [fecha1],
        comparer: comparerSelected.code,
        type: columnSelected.type
      };
      onFilterAdvanced(newFilter);
      setDialogVisible(false);
    }
  };

  const validate = () => {
    if (!columnSelected) return false;

    if (columnSelected.type === 'number') {
      if (columnSelected.code === 'entre' && (value1Comparer === null || value2Comparer === null)) return false;
      if (value1Comparer === null) return false;
    }

    if (columnSelected.type === 'datetime') {
      if (columnSelected.code === 'entre' && (date1 === null || date2 === null)) return false;
      if (date1 === null) return false;
    }

    if (columnSelected.type === 'string' && valueComparerText === '') return false;

    return true;
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="Cancelar" icon="pi pi-times" onClick={() => setDialogVisible(false)} className="p-button-text" />
        <Button label="Añadir Filtro" icon="pi pi-check" onClick={() => addNewFilter()} autoFocus />
      </div>
    );
  };

  return (
    <>
      <Button
        icon="pi pi-filter"
        className="p-button-rounded p-button-text p-button-plain p-4"
        aria-label="Filter"
        onClick={(event) => setDialogVisible(true)}
      />
      <Dialog
        header="Añadir filtro"
        visible={dialogVisible}
        footer={renderFooter()}
        style={{ width: '400px' }}
        onHide={() => setDialogVisible(false)}
      >
        <div className="cardFilter">
          <label
            htmlFor="campo"
            className="block"
          >
            <h5>Campo</h5>
          </label>

          <Dropdown
            value={columnSelected}
            options={parameterFilterAdvanced}
            optionLabel="categoryDisplay"
            className='field'
            onChange={(e) => {
              setColumnSelected(e.value);
              const filteredComparerSources = comparerSourcesBase.filter(comparerSource => comparerSource.types.includes(e.value.type));
              setComparerSources(filteredComparerSources);
              setComparerSelected(filteredComparerSources[0]);
            }}
          />

          {comparerSelected && (
            <>
              <label
                htmlFor="comparer"
                className="block my-2"
              >
                <h5>Comparador</h5>
              </label>

              <Dropdown
                value={comparerSelected}
                options={comparerSources}
                optionLabel="name"
                className='field'
                onChange={(e) => setComparerSelected(e.value)}
              />

              {columnSelected.type === 'number' && (
                <>
                  <label
                    htmlFor="value1"
                    className="block my-2"
                  >
                    <h5>Valor 1</h5>
                  </label>

                  <InputNumber
                    inputId="value1"
                    value={value1Comparer}
                    onValueChange={(e) => setValue1Comparer(e.value)}
                    mode="decimal"
                    showButtons
                    min={0}
                    max={1000000}
                  />

                  {comparerSelected.code === 'entre' && (
                    <>
                      <label htmlFor="value2" className='mt-4 mb-2'>
                        <h5>Valor 2</h5>
                      </label>

                      <InputNumber
                        inputId="value2"
                        value={value2Comparer}
                        onValueChange={(e) => setValue2Comparer(e.value)}
                        mode="decimal"
                        showButtons min={0}
                        max={1000000}
                      />
                    </>
                  )}
                </>
              )}

              {columnSelected.type === 'datetime' && (
                <>
                  <label
                    htmlFor="Fecha"
                    className="block my-2"
                  >
                    <h5>Fecha 1</h5>
                  </label>

                  <Calendar
                    id="time24"
                    value={date1}
                    onChange={(e) => setDate1(e.value)}
                    showTime
                    showSeconds
                  />

                  {comparerSelected.code === 'entre' && (
                    <>
                      <label htmlFor="Fecha" className="block my-2">
                        <h5>Fecha 2</h5>
                      </label>

                      <Calendar
                        id="time24"
                        value={date2}
                        onChange={(e) => setDate2(e.value)}
                        showTime
                        showSeconds
                      />
                    </>
                  )}
                </>
              )}

              {columnSelected.type === 'string' && (
                <>
                  <label
                    htmlFor="value1"
                    className="block my-2"
                  >
                    <h5>Valor</h5>
                  </label>

                  <InputText
                    id="value1"
                    value={valueComparerText}
                    onInput={(e) => setValueComparerText(e.target.value)}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}
