import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useContext } from 'react';
import { svgIconBackgroundStyle, svgIconTextStyle } from 'assets/styles';
import NotificationContext from './context/notification-context';
import { createMessageService } from 'services/notifications-service';
import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import moment from 'moment/moment';

export function SvgNotifications () {
  const { notificationsShow, notificationsTopics, notificationsToggle } = useContext(NotificationContext);
  const onSubmit = async (data) => {
    const _message = {
      ...data,
      users: notificationsTopics,
      sentAt: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    await createMessageService(_message);
    cancelDialog();
  };

  const { register, handleSubmit, formState: { errors } } = useForm();

  const cancelDialog = () => {
    notificationsToggle([]);
  };

  const renderFooter = () => {
    return (
      < >
        <Button
          label='Cancelar'
          icon='pi pi-times'
          onClick={() => cancelDialog()}
          className='p-button-text' />
        <Button
          label='Enviar'
          icon='pi pi-check'
          form='svg-form-send-message'
          type='submit'
          autoFocus />
      </ >
    );
  };

  return (
    < >
      <Dialog
        header='Enviar notificaciones'
        modal
        visible={notificationsShow}
        onHide={cancelDialog}
        footer={renderFooter}
        style={{ width: '50vw' }}>
        <form onSubmit={handleSubmit(onSubmit)} className='grid p-fluid' id='svg-form-send-message'>
          <div className='col-12 md:col-12'>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon' style={svgIconBackgroundStyle}>
                <i className='pi pi-circle' style={svgIconTextStyle}></i>
              </span>
              <InputText placeholder='Titulo'
                {...register('title', { required: true, minLength: 2, maxLength: 90 })} />
            </div>
            {errors.title && <small className='p-error'>El titulo es requerido y debe tener mas de 20 y menos de 90 caracteres.</small>}
          </div>
          <div className='col-12 md:col-12'>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon' style={svgIconBackgroundStyle}>
                <i className='pi pi-key' style={svgIconTextStyle}></i>
              </span>
              <InputTextarea
                rows={7}
                {...register('description', { required: true, minLength: 2, maxLength: 200 })}
                placeholder='Mensaje' />
            </div>
            {errors.description && <small className='p-error'>La descripción es requerido y debe tener mas de 30 y menos de 200 caracteres.</small>}
          </div>
        </form>
      </Dialog>
    </>
  );
}
