import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  codeQr: { cl: null, ct: null, nm: null }
};

const reducers = {
  setQr: (state, action) => {
    state.codeQr = action.payload;
  }
};

const codeQrManagementSlice = createSlice({
  name: 'codeQrManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = codeQrManagementSlice;

// ACTIONS
export const { setQr } = actions;

// GETTERS
export const selectCodeQrManagementState = (state) => state.codeQrManagement;

export default reducer;
