import PropTypes from 'prop-types';

export function SpsBackground ({ height, width }) {
  const defaultWidth = width || '60';
  const defaultHeight = height || '62';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={defaultWidth}
      height={defaultHeight}
      viewBox="0 0 100 102"
    >
      <g
        transform="matrix(0.1,0,0,-0.1,-25.602712,88.846581)"
        fill="#000000"
        stroke="none"
        id="g381">
        <path
          d="M 538,875 C 394,836 284,711 260,562 c -13,-75 7,-183 44,-246 46,-78 47,-78 106,49 38,83 58,115 70,115 15,0 17,6 13,40 -11,83 51,176 134,199 21,6 100,11 176,11 75,0 137,4 137,9 0,5 -17,24 -37,43 -106,94 -238,128 -365,93 z"
          id="path303"
          fill="#f2f2f2" />
        <path
          d="m 600,608 c -40,-43 -25,-53 93,-58 104,-5 110,-6 133,-33 38,-44 28,-105 -24,-160 -44,-47 -61,-52 -202,-57 l -135,-5 -17,-35 c -29,-63 -27,-73 21,-97 24,-12 69,-27 100,-34 137,-29 309,45 382,165 16,26 19,51 19,162 0,105 3,134 15,144 8,7 15,16 15,21 0,5 -85,9 -190,9 -183,0 -190,-1 -210,-22 z"
          id="path335"
          fill="#f2f2f2"/>
        <path
          d="m 522,405 -12,-25 h 106 c 107,0 144,9 144,35 0,12 -20,15 -113,15 -112,0 -114,0 -125,-25 z"
          id="path337"
          fill="#f2f2f2" />
      </g>
    </svg>
  );
}

SpsBackground.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};
