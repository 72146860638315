import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import moment from 'moment';
import { saveAsExcelFile } from 'screens/private/shifts/components/shifts/helpers';
import { SvgFilterOdoo } from 'components';
import './svg-table.scss';

export function SvgTable (props) {
  const { onUpdateItemsSelected = () => { } } = props;
  // filters
  const parametersFilter = [
    { categoryDisplay: 'Usuario', categoryValue: 'name' },
    { categoryDisplay: 'Campo 1: Number Input', categoryValue: 'Campo 1: Number Input' },
    { categoryDisplay: 'Campo 2: Selector Estatico', categoryValue: 'Campo 2: Selector Estatico' },
    { categoryDisplay: 'Campo 3: Text Input', categoryValue: 'Campo 3: Text Input' },
    { categoryDisplay: 'Campo 10: Selector Dinamico', categoryValue: 'Campo 10: Selector Dinamico' },
    { categoryDisplay: 'Campo 12: Fecha', categoryValue: 'Campo 12: Fecha' }
  ];

  const columns = props.columns || [];
  const toggleModal = (props.toggleCreate) ? props.toggleCreate : false;
  const onReload = (props.onReload) ? props.onReload : () => { };
  const elements = props.elements || [];
  const emptyMessage = props.emptyMessage || 'No customers found.';
  const addButtons = props.addButtons || '';
  const selectable = props.selectable || false;
  const rowsDefault = props.rows || 10;
  const isDownloadPdf = props.downloadPdf;

  const [firstPage, setFirstPage] = useState(0);
  const [rows, setRows] = useState(rowsDefault);
  const [parameterFilter, setParameterFilter] = useState(parametersFilter);
  const [selectedCustomers1, setSelectedCustomers1] = useState([]);

  // const [selectedCustomers1, setSelectedCustomers1] = useState([null]); // this retunrs an error: "Uncaught TypeError: Cannot read properties of null (reading 'rowIndex')"

  const nameStorageFilter = 'form_inputs_filter';
  let filterInit = [];
  const filterStorage = JSON.parse(sessionStorage.getItem(nameStorageFilter));
  if (filterStorage) filterInit = filterStorage;

  const [filterListCurrent, setFilterListCurrent] = useState(filterInit);
  const [filter, setFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const [filterListCurrentDefault] = useState(filterInit);

  // const mutateFilter = (data) => {
  //   const totalItems = data.length;
  //   if (!columns || columns == null || columns.length === 0) return parameterFilter;
  //   for (let i = 0; i < totalItems; i++) {
  //     const item = data[i];
  //     const column = columns.find((col) => col.header === item.categoryDisplay);
  //     item.categoryValue = column.filterField;
  //   }
  // };
  // mutateFilter(parameterFilter);

  useEffect(() => {
    onApplyFilterOdoo([...filterListCurrent]);
    const filter = columns.map(column => ({ categoryDisplay: column.header, categoryValue: column.field, type: column.type })).filter(field => !['image', 'sign', 'title', 'paragraph'].includes(field.type));
    setParameterFilter(filter);
  }, [columns]);

  const onFilterSignals = (filterList) => {
    setFilterListCurrent(filterList);
    onApplyFilterOdoo(filterList);
    sessionStorage.setItem(nameStorageFilter, JSON.stringify(filterList));
  };

  const onApplyFilterOdoo = (filterList) => {
    const _filters = {};
    _filters.global = { value: null, matchMode: FilterMatchMode.CONTAINS };
    filterList.forEach((item) => {
      _filters[item.categoryValue] = {
        operator: FilterOperator.OR,
        constraints: item.values.map((v) => {
          return { value: v, matchMode: FilterMatchMode.CONTAINS };
        })
      };
    });
    setFilter(_filters);
  };

  const onCustomPage = (event) => {
    setFirstPage(event.first);
    setRows(event.rows);
  };

  const positionButtons = toggleModal || props.filtereable ? 'between' : 'end';
  const styleButtons = classNames(
    'flex',
    `justify-content-${positionButtons}`
  );

  const actions = <div>
    <Button
      type='button'
      icon='pi pi-plus-circle'
      className='mr-2 h-full'
      tooltip='Añadir Elemento'
      onClick={() => toggleModal()}
      tooltipOptions={{ position: 'top' }} />
    {addButtons}
  </div>;

  const isValidHttpUrl = (value) => {
    let url;
    try {
      url = new URL(value);
    } catch {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const exportExcel = (data, title = 'svg') => {
    try {
      if (data == null) return false;
      const ws = XLSX.utils.aoa_to_sheet(data, { cellStyles: true });
      const defMsg = 'Enlace, Haz click aquí';

      for (const key in ws) {
        const cell = ws[key];
        if (isValidHttpUrl(cell.v) === true) {
          cell.l = { Target: cell.v, Tooltip: defMsg };
          cell.v = defMsg;
        }
      }

      const workbook = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, title);
    } catch (error) {
      toast.error('Error al descargar el archivo, por favor vuelve a intentar.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const mapExportedData = (data) => {
    if (data == null || data[0] == null || data.length === 0) return [];
    const mappedData = [];
    for (const row of data) {
      const rowMapped = [];
      for (const key in row) {
        const indexColumn = columns.findIndex(column => column.field === key);
        if (indexColumn >= 0) {
          rowMapped[indexColumn] = row[key];
        }
      }
      mappedData.push(rowMapped);
    }
    mappedData.unshift(columns.map(column => column.header));
    return mappedData;
  };

  const header = () => {
    return (
      <div className={styleButtons}>
        <div className='flex flex-row'>
          {!props.downlable && <Button
            type="button"
            icon="pi pi-file-excel"
            className="mx-2 px-3"
            tooltip='Descargar Reporte'
            tooltipOptions={{ position: 'top' }}
            onClick={() => exportExcel(mapExportedData(selectedCustomers1), 'svg-entradas-formularios')} />}
          {isDownloadPdf && <Button
            type="button"
            icon="pi pi-file-pdf"
            className="ml-2 mr-4 px-3"
            tooltip='Descargar Reporte en PDF'
            tooltipOptions={{ position: 'top' }}
            onClick={() => props.onExportPdf(selectedCustomers1)}
          />}

          {props.filtereable && props.headerFilter}
          {toggleModal && actions}

        </div>
        <div>
          <span className='p-input-icon-left ml-1' style={{ minWidth: '26rem' }}>
            <SvgFilterOdoo
              parametersFilter={parameterFilter}
              defaultParameterFilter={parameterFilter[0]}
              onFilterSignals={onFilterSignals}
              filterDefault={filterListCurrentDefault}
              filterTimeStamp={[]}
            ></SvgFilterOdoo>
          </span>
        </div>
      </div>
    );
  };

  const nameBodyTemplate = (rawData) => {
    return <Chip
      label={rawData.name || rawData.novelty || rawData.mail}
      icon="pi pi-map"
      className="px-4 places-name places-ellipsis" />;
  };

  const imageBodyTemplate = (row, field) => {
    return <img
      src={row[field]}
      onError={(e) => 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
      alt={row.user}
      className="product-image" />;
  };
  const dynamicColumns = columns?.map((col) => {
    if (col.type === 'sign' || col.type === 'image') {
      return <Column
        key={col.field}
        sortable
        body={(row) => imageBodyTemplate(row, col.field)}
        {...col}
      />;
    };
    if (col.field === 'name' || col.field === 'novelty' || col.field === 'mail') {
      return <Column
        key={col.field}
        sortable
        body={nameBodyTemplate}
        {...col}
      />;
    };
    if (col.field === 'updated_at') {
      return <Column
        key={col.field}
        sortable
        body={(row) => moment(row.updated_at).format('YYYY-MM-DD HH:mm')}
        {...col}
      />;
    }
    return (
      <Column
        key={col.field}
        sortable
        {...col}
      />
    );
  });

  const templatePaginator = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 }
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items por página: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    }
  };
  const paginatorLeft = <Button type='button' icon='pi pi-refresh' onClick={onReload} className='p-button-text' />;

  return (
    <div className='card'>
      <DataTable
        value={elements}
        selection={selectedCustomers1}
        onSelectionChange={e => {
          onUpdateItemsSelected(e.value);
          setSelectedCustomers1(e.value);
        }}
        dataKey='id'
        size='small'
        paginatorTemplate={templatePaginator}
        first={firstPage}
        onPage={onCustomPage}
        currentPageReportTemplate='Mostrando {first} a {last} desde {totalRecords}'
        paginatorLeft={paginatorLeft}
        paginator
        showGridlines={false}
        rows={rows}
        filters={filter}
        filterDisplay='menu'
        responsiveLayout='scroll'
        rowsPerPageOptions={[5, 10, 20, 50]}
        header={header}
        emptyMessage={emptyMessage}>
        {selectable &&
          <Column
            selectionMode='multiple'
            headerStyle={{ width: '3em' }} />
        }
        {dynamicColumns}
        {props.showdable &&
          <Column
            bodyStyle={{ textAlign: 'right', overflow: 'visible', minWidth: '4rem' }}
            body={props.actionBodyTemplate} />
        }
      </DataTable>
    </div>
  );
}
