import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: []
};

const reducers = {
  setNotifications: (state, action) => {
    state.notifications = action.payload;
  }
};

const notificationSlice = createSlice({
  name: 'notification-slice',
  initialState,
  reducers
});

const { actions, reducer } = notificationSlice;

// SETTERS
export const { setNotifications } = actions;

// GETTERS
export const selectNotificationsState = (state) => state.notificationManagement;

export default reducer;
