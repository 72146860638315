const services = process.env.REACT_APP_SERVICES_SERVICES;
const users = process.env.REACT_APP_SERVICES_USERS;
const forms = process.env.REACT_APP_SERVICES_FORMS;
const memorandums = process.env.REACT_APP_SERVICES_MEMORANDUMS;

export const bff = [
  {
    api: services,
    allow: [
      'round',
      'category',
      'contract',
      'point',
      'service',
      'shift',
      'place',
      'novelty'
    ]
  },
  {
    api: users,
    allow: [
      'auth',
      'token',
      'users',
      'cities',
      'states',
      'countries',
      'profiles',
      'permissions',
      'modules',
      'restore',
      'areas',
      'emails',
      'companies',
      'notifications'
    ]
  },
  {
    api: forms,
    allow: [
      'form',
      'response',
      'list',
      'resources',
      'blogs',
      'reports',
      'groups'
    ]
  },
  {
    api: memorandums,
    allow: [
      'novelties',
      'messages',
      'memorandums',
      'labels'
    ]
  }
];
