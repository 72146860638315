import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  restore: false
};

const reducers = {
  setRestoreStatus: (state, action) => {
    state.restore = action.payload;
  }
};

const restoreManagementSlice = createSlice({
  name: 'restoreManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = restoreManagementSlice;

// ACTIONS
export const {
  setRestoreStatus
} = actions;

// GETTERS
export const selectRestoreManagementState = (state) => state.restoreManagement;

export default reducer;
