import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

const initialState = {
  // permissions: null,
  profiles: null,
  name: '',
  is_company: null,
  ttl: null,
  token: null
};

const reducers = {
  setAuth: (state, action) => {
    const { token, token_expiration } = action.payload;
    const decode = jwt_decode(token);

    // Tiene que ser modificado
    const currentTime = new Date().getTime();
    if (currentTime > token_expiration) return;

    localStorage.setItem('svg-access', JSON.stringify({ token, token_expiration }));
    // state.permissions = decode.permission;
    state.profiles = decode.profiles;
    state.name = decode.name;
    state.is_company = decode.is_company;
    state.ttl = token_expiration;
    state.token = token;
  },

  setLogout: (state) => {
    localStorage.removeItem('svg-access');
    // state.permissions = [];
    state.profiles = [];
    state.name = '';
    state.is_company = null;
    state.ttl = null;
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers
});

const { actions, reducer } = authSlice;

// ACTIONS
export const { setAuth, setLogout } = actions;

// GETTERS
export const selectAuthState = (state) => state.auth;
export const isAuthenticated = (state) => {
  const payload = JSON.parse(localStorage.getItem('svg-access'));
  if (!payload || !payload.token || !payload.token_expiration) return false;
  if (!state.auth.token) {
    const currentTime = new Date().getTime();
    if (currentTime > payload.token_expiration) return false;
  //   setAuth(payload);
  //   return true;
  }

  const currentTime = new Date().getTime();
  const ttl = state.auth.ttl;
  if (!ttl) return false;
  return (currentTime < ttl);
};

// Estan en el archivo de api
export default reducer;
