import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cities: []
};

const reducers = {
  setCities: (state, action) => {
    state.cities = action.payload;
  },
  addCity: (state, action) => {
    state.cities = [action.payload, ...state.cities];
  },
  deleteCities: (state, action) => {
    const cities = [...state.cities];
    state.cities = [...cities.filter(city => city.id !== action.payload.id)];
  },
  updateCities: (state, action) => {
    const cities = [...state.cities];
    cities[cities.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.cities = cities;
  }
};

const citiesManagementSlice = createSlice({
  name: 'citiesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = citiesManagementSlice;

// ACTIONS
export const {
  setCities,
  addCity,
  deleteCities,
  updateCities
} = actions;

// GETTERS
export const selectCitiesManagementState = (state) => state.citiesManagement;

export default reducer;
