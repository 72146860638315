import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  states: []
};

const reducers = {
  setStates: (state, action) => {
    state.states = action.payload;
  },
  addState: (state, action) => {
    state.states = [action.payload, ...state.states];
  },
  deleteStates: (state, action) => {
    const states = [...state.states];
    state.states = [...states.filter(item => item.id !== action.payload.id)];
  },
  updateStates: (state, action) => {
    const states = [...state.states];
    states[states.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.states = states;
  }
};

const statesManagementSlice = createSlice({
  name: 'statesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = statesManagementSlice;

// ACTIONS
export const {
  setStates,
  addState,
  deleteStates,
  updateStates
} = actions;

// GETTERS
export const selectStatesManagementState = (state) => state.statesManagement;

export default reducer;
