import { Button } from 'primereact/button';
import { exportExcel } from 'screens/private/shifts/components/shifts/helpers';

export function SvgExcelExport (props) {
  return (
    <Button
    type="button"
    icon="pi pi-file-excel"
    className="mr-2"
    tooltip='Descargar Turnos'
    tooltipOptions={{ position: 'top' }}
    onClick={() => exportExcel(props.data)} />
  );
};
