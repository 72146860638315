import PropTypes from 'prop-types';
import conectaImg from 'assets/img/sps-img.png';

export function SpsLogo ({ width, height }) {
  return (
    <img src={conectaImg} alt="svg logo" width={width} height={height} />
  );
}

SpsLogo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};
