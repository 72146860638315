import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shifts: []
};

const reducers = {
  getShifts: (state, action) => {
    state.shifts = action.payload;
  },
  changeStatus: (state, action) => {
    const index = state.shifts.findIndex(shift => shift.id === action.payload.id);
    state.shifts[index].status = action.payload.status;
  },
  deleteShifts: (state, action) => {
    const shifts = [...state.shifts];
    state.shifts = [...shifts.filter(item => item.id !== action.payload.id)];
  },
  updateShifts: (state, action) => {
    const shifts = [...state.shifts];
    shifts[shifts.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.shifts = [...shifts];
  },
  clearShifts: (state) => {
    state.shifts = [];
  }
};

const shiftsManagementSlice = createSlice({
  name: 'shiftsManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = shiftsManagementSlice;

// ACTIONS
export const { getShifts, changeStatus, deleteShifts, updateShifts, clearShifts } = actions;

// GETTERS
export const selectShiftsManagementState = (state) => state.shiftsManagement;

export default reducer;
