// import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { isAuthenticated } from 'store/session/signin-slice';
// import { useEffect } from 'react';

export function PrivateRoute ({ children }) {
  // const Authenticated = useSelector(isAuthenticated);
  // useEffect(() => {
  // });
  // return Authenticated ? children : <Navigate replace to='/' />;
  return children;
}
