import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: []
};

const reducers = {
  setUsers: (state, action) => {
    state.users = action.payload;
  },
  addUser: (state, action) => {
    action.payload.full_name = `${action.payload.name} ${action.payload.first_name} ${action.payload.last_name}`;
    state.users = [...state.users, action.payload];
  },
  deleteUsers: (state, action) => {
    const users = [...state.users];
    state.users = [...users.filter(item => item.id !== action.payload.id)];
  }
};

const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = usersManagementSlice;

// ACTIONS
export const {
  setUsers,
  addUser,
  deleteUsers
} = actions;

// GETTERS
export const selectUsersManagementState = (state) => state.usersManagement;

export default reducer;
