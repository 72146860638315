import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/svg/application.scss';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { PrivateRoute } from 'routes/private-route';
import { useDispatch, useSelector } from 'react-redux';
import { SvgSpinner } from 'components';
import { menu, AxiosClient } from 'config';
import { setSection } from 'store/ui/ui-slice';
import { setAuth, selectAuthState, setLogout } from 'store/session/signin-slice';
/*************************************************************************************************
 * RUTAS PUBLICAS
 ************************************************************************************************/
const SvgSigninScreen = lazy(() => import('screens/public/signin/signin'));
const SvgSignupScreen = lazy(() => import('screens/public/signup/signup'));
const SvgRestoreScreen = lazy(() => import('screens/public/restore/restore'));
const SvgValidateScreen = lazy(() => import('screens/public/validate/validate'));
const SpsValidateScreen = lazy(() => import('screens/public/validate/validate.sps'));
/*************************************************************************************************
 * RUTAS PRIVADAS GENERAL
 ************************************************************************************************/
const SvgAccountsScreen = lazy(() => import('screens/private/accounts/accounts'));
const SvgFormsScreen = lazy(() => import('screens/private/forms/forms'));
const SvgMemorandumsScreen = lazy(() => import('screens/private/memorandums/memorandums'));
const SvgRoundsScreen = lazy(() => import('screens/private/rounds/rounds'));
const SvgServicesScreen = lazy(() => import('screens/private/services/services'));
const SvgShiftsScreen = lazy(() => import('screens/private/shifts/shifts'));
const SvgResourcesScreen = lazy(() => import('screens/private/resources/resources'));
const DashboardLayout = lazy(() => import('screens/layouts/main-layout/main-layout'));
/*************************************************************************************************
 * RUTAS PRIVADAS ADMIN
 ************************************************************************************************/
// const SvgLocationsScreen = lazy(() => import('screens/private/locations/locations'));
const SvgSecurityScreen = lazy(() => import('screens/private/security/security'));
const SvgNoveltiesScreen = lazy(() => import('screens/private/novelties/novelties'));
// const SvgTypesScreen = lazy(() => import('screens/private/types/types'));
// const SvgCompaniesScreen = lazy(() => import('screens/private/companies/companies'));
/***********************************************************************************************/

function App () {
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector(selectAuthState);
  useEffect(() => {
    const payload = JSON.parse(localStorage.getItem('svg-access'));
    if (!payload || !payload.token || !payload.token_expiration) return;
    if (!token) {
      const currentTime = new Date().getTime();
      if (currentTime > payload.token_expiration) dispatch(setLogout());
      AxiosClient.defaults.headers.common.Authorization = 'Bearer ' + payload.token;
      dispatch(setAuth(payload));
    }
  }, []);

  useEffect(() => {
    const menuList = [...menu[0].items, ...menu[0].adminMenu];
    const index = menuList.findIndex((menu) => menu.to === location.pathname);
    if (index < 0) return;
    dispatch(setSection({
      label: menuList[index].label,
      index,
      to: menuList[index].to,
      module: menuList[index].module
    }));
  }, []);

  return (
    <Suspense fallback={<SvgSpinner />}>
      <Routes>
        <Route path='/' element={<SvgSigninScreen />} />
        <Route path='/signup' element={<SvgSignupScreen />} />
        <Route path='/restore' element={<SvgRestoreScreen />} />
        <Route path='/validate/:user/:token' element={<SvgValidateScreen />} />
        <Route path='/soloporservicios/:user/:token' element={<SpsValidateScreen />} />

        <Route path='/dashboard' element={<PrivateRoute> <DashboardLayout /> </PrivateRoute>}>
          <Route path='' element={<Navigate to='shifts' replace={true} />} />
          <Route path='users' element={<SvgAccountsScreen />} />
          <Route path='memorandums' element={<SvgMemorandumsScreen />} />
          <Route path='rounds' element={<SvgRoundsScreen />} />
          <Route path='forms' element={<SvgFormsScreen />} />
          <Route path='shifts' element={<SvgShiftsScreen />} />
          <Route path='services' element={<SvgServicesScreen />} />
          <Route path='resources' element={<SvgResourcesScreen />} />
          <Route path='security' element={<SvgSecurityScreen />} />
          <Route path='novelties' element={<SvgNoveltiesScreen />} />
        </Route>
        <Route path='*' element={<Navigate replace to="/" />} />
      </Routes>
      <ToastContainer autoClose={2000} limit={4} />
    </Suspense>
  );
}

export default App;
