import PropTypes from 'prop-types';

export function SvgBackground ({ height, width }) {
  const defaultWidth = width || '1436.028';
  const defaultHeight = height || '1397.272';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={defaultWidth}
      height={defaultHeight}
      viewBox='0 0 1436.028 1397.272'
    >
      <defs>
        <filter
          id='Path_1593'
          x='0'
          y='0'
          width='1436.028'
          height='1397.272'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='16' input='SourceAlpha' />
          <feGaussianBlur stdDeviation='10' result='blur' />
          <feFlood floodOpacity='0.161' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(#Path_1593)'>
        <path
          id='Path_1593-2'
          data-name='Path 1593'
          d='M2.7,154.312c12.42,221.4,91.712,443.036,180.282,615.1,49.037-18.176,162.825-87.585,236.627-95.916,24.613,73.423,140.711,210.84,192.323,273.244l80.239,76.452c73.65-45.44,236.7-279.681,271.766-349.241,26.2,5.718,100.8,27.832,117.083,42.6-9.58,30.861-59.147,105.041-78.5,133.668C960.529,912.283,912.59,978.208,860.41,1029.4c-19.388,18.933-31.467,36.768-51.536,55.55-24.537,22.947-89.857,87.4-116.7,102.921C581.638,1104.076,429,915.842,360.082,824.811c-40.593,8.634-78.345,36.427-112.312,52.218,30.293,75.2,182.175,246.434,239.429,301.567,34.08,32.792,167.445,163.28,210.5,161.272,151.087-6.967,526.91-552.545,558.794-688.561-41.653-28.476-117.651-67.364-283.051-108.942-101.482-25.522-244.73-43.395-354.088-32.338,6.475,22.379,44.417,90.084,57.973,108.941C694.139,642.3,764.8,636.5,829.435,644.265,826.33,676.489,711.027,821.782,693.8,839.5c-36.238-23.174-122.27-154.116-146.959-197.132C519.954,595.606,449.22,460.613,442.177,407.6c238.974-59.829,539.178-16.4,767.55,85.881,28.021,12.534,62.858,33.247,87.623,41.994l39.608-118.408c-42.751-37-161.046-75.733-222.54-95.764-182.856-58.882-367.076-78.118-561.179-59.147-103.3,10.072-176.344,34.08-269.835,51.46,8.1,78.762,50.741,171.307,75.733,241.625l-116.4,41.653C201.915,526.841,152.8,326.6,140.5,233.756c227.2-74.067,373.778-115.719,632.368-101.027,271.047,15.222,389.076,57.292,588.746,151.882,11.89-29.725,19.652-96.748,16.358-130.412-62.593-40.29-229.432-89.1-310.5-108.26A1657.078,1657.078,0,0,0,692.17,2.62C471.485,2.7,169.425,60.858,2.7,154.312Z'
          transform='translate(27.3 11.38)'
          fill='#ffffff'
          opacity='0.54'
        />
      </g>
    </svg>
  );
}

SvgBackground.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};
