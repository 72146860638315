import { configureStore } from '@reduxjs/toolkit';

import siginReducer from './session/signin-slice';
import uiReducer from './ui/ui-slice';
import roundsReducer from './rounds/rounds-slice';
import placesReducer from './places/places-slice';
import categoriesReducer from './categories/categories-slice';
import servicesReducer from './services/services-slice';

// Parametros
import usersReducer from './accounts/users-slice';
import contractsReducer from './contracts/contracts-slice';
import shiftsReducer from './shifts/shifts-slice';
import citiesReducer from './places/locations/cities-slice';
import statesReducer from './places/locations/states-slice';
import countriesReducer from './places/locations/countries-slice';
import modulesReducer from './accounts/clasification/modules-slice';
import groupsReducer from './accounts/clasification/groups-slice';
import permissionsReducer from './accounts/clasification/permissions-slice';
import profilesReducer from './accounts/clasification/profiles-slice';
import restoreReducer from './accounts/restore/restore-slice';
import formsReducer from './forms/forms-slice';
import ListReducer from './forms/lists.store';
import qrReducer from './rounds/code-qr-slice';
import noveltiesReducer from './memorandum/novelties-slice';
import employeesReducer from './accounts/employee-slice';
import companiesReducer from './companies/companies-slice';
import areasReducer from './companies/areas-slice';
import emailsReducer from './companies/emails-slice';
import memorandumsReducer from './memorandum/memorandums-slice';
import notificationReducer from './notifications/notifications-slice';

export const store = configureStore({
  reducer: {
    auth: siginReducer,
    ui: uiReducer,
    roundsManagement: roundsReducer,
    placesManagement: placesReducer,
    categoriesManagement: categoriesReducer,
    servicesManagement: servicesReducer,
    shiftsManagement: shiftsReducer,

    // Parametros
    usersManagement: usersReducer,
    contractsManagement: contractsReducer,
    citiesManagement: citiesReducer,
    statesManagement: statesReducer,
    countriesManagement: countriesReducer,
    modulesManagement: modulesReducer,
    permissionsManagement: permissionsReducer,
    profilesManagement: profilesReducer,
    restoreManagement: restoreReducer,
    formsManagement: formsReducer,
    listsManagement: ListReducer,
    codeQrManagement: qrReducer,
    noveltiesManagement: noveltiesReducer,
    employeesManagement: employeesReducer,
    companiesManagement: companiesReducer,
    areasManagement: areasReducer,
    emailsManagement: emailsReducer,
    memorandumsManagement: memorandumsReducer,
    groupsManagement: groupsReducer,
    notificationManagement: notificationReducer
  }
});
