import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profiles: []
};

const reducers = {
  setProfiles: (state, action) => {
    state.profiles = action.payload;
  },
  addProfile: (state, action) => {
    state.profiles = [action.payload, ...state.profiles];
  },
  deleteProfile: (state, action) => {
    const profiles = [...state.profiles];
    state.profiles = [...profiles.filter(item => item.id !== action.payload.id)];
  },
  updateProfile: (state, action) => {
    const profiles = [...state.profiles];
    const index = profiles.findIndex(item => item.id === action.payload.id);
    profiles[index] = action.payload;
    state.profiles = profiles;
  }
};

const profilesManagementSlice = createSlice({
  name: 'profilesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = profilesManagementSlice;

// ACTIONS
export const {
  setProfiles,
  addProfile,
  deleteProfile,
  updateProfile
} = actions;

// GETTERS
export const selectProfilesManagementState = (state) => state.profilesManagement;

export default reducer;
