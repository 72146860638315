import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  services: []
};

const reducers = {
  getServices: (state, action) => {
    state.services = action.payload;
  },
  deleteService: (state, action) => {
    const services = [...state.services];
    state.services = [...services.filter(item => item.id !== action.payload.id)];
  },
  updateService: (state, action) => {
    const services = [...state.services];
    services[services.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.services = services;
  }
};

const servicesManagementSlice = createSlice({
  name: 'servicesManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = servicesManagementSlice;

// ACTIONS
export const { getServices, deleteService, updateService } = actions;

// GETTERS
export const selectServicesManagementState = (state) => state.servicesManagement;

export default reducer;
