import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { SvgLogo } from 'components';
import { setSection } from 'store/ui/ui-slice';
import { useDispatch } from 'react-redux';
// import AccessContext from 'components/svg-access/context/access-context';

const SvgSubmenu = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const onMenuItemClick = (event, item, index) => {
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item
      });
    }
    dispatch(setSection({ label: item.label, index, to: item.to, module: item.module }));
  };

  const onKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.target.click();
    }
  };

  const renderLinkContent = (item) => {
    return (
      <React.Fragment>
        <i className={item.icon}></i>
        <h6>{item.label}</h6>
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    const content = renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink aria-label={item.label}
          onKeyDown={onKeyDown}
          role='menuitem'
          to={item.to}
          onClick={(e) => onMenuItemClick(e, item, i)}
          target={item.target}>
          {content}
        </NavLink>
      );
    } else {
      return (
        <a tabIndex='0'
          aria-label={item.label}
          onKeyDown={onKeyDown}
          role='menuitem'
          href={item.url}
          className='p-ripple'
          onClick={(e) => onMenuItemClick(e, item, i)}
          target={item.target}>
          {content}
        </a>
      );
    }
  };

  const items = props.items && props.items.map((item, i) => {
    const active = item.to === location.pathname;

    const styleClass = classNames(
      { 'active-menuitem': active, 'disabled-menuitem': !item.enabled }
    );

    if (props.root) {
      return (
        <li key={i} role='none'>
          {props.root === true &&
            <React.Fragment>
              <SvgSubmenu
                items={item.items}
                onMenuItemClick={props.onMenuItemClick} />
            </React.Fragment>
          }
        </li>
      );
    } else {
      return (
        <li className={styleClass} key={i} role='none'>
          {renderLink(item, i)}
        </li>
      );
    }
  });
  return items ? <ul className={props.className} role='menu'>{items}</ul> : null;
};

export function SvgSidebar (props) {
  // const { validateModule } = useContext(AccessContext);
  const getMenu = () => {
    const items = props.model[0].items.map((m) => {
      m.enabled = true; // validateModule(m.module);
      return m;
    });
    const newModel = props.model;
    newModel.items = items;
    return [newModel];
  };
  return (
    <div className='layout-menu-container flex justify-content-center align-items-center flex-column shadow-2'>
      <span className='mb-3'>
        <SvgLogo width='95' />
      </span>
      <SvgSubmenu
        items={getMenu()}
        className='layout-menu'
        onMenuItemClick={props.onMenuItemClick}
        root={true}
        role='menu' />
    </div>
  );
}
