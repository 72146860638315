import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userSelection: { label: 'Gestión de Usuarios', index: 0, to: '/dashboard/users', module: 'users' },
  showSidebar: true,
  sendNotification: { show: false, topic: [] }
};

const reducers = {
  setSelection: (state, action) => {
    state.userSelection = action.payload;
  },
  setSidebar: (state, action) => {
    state.showSidebar = action.payload;
  },
  setSection: (state, action) => {
    state.userSelection = action.payload;
  },
  setNotifications: (state, action) => {
    state.sendNotifications = action.payload;
  }
};

const uiSlice = createSlice({
  name: 'ui', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = uiSlice;

// ACTIONS
export const {
  setSelection,
  setSection,
  setSidebar,
  setNotifications
} = actions;

// GETTERS
export const selectUiState = (state) => state.ui;

export default reducer;
