import axios from 'axios';
import { bff } from '../constants/bff';

const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_A_URL,
  headers: {
    'Content-type': 'application/json',
    channel: 'SVG'
  }
});

AxiosClient.interceptors.request.use(function (config) {
  const urlSplit = config.url.split(/(\/|\?)/s);
  const baseURL = bff.find(resp => resp.allow.includes(urlSplit[0] ? urlSplit[0] : urlSplit[1]));
  if (!baseURL) return Promise.reject(new Error('Valida que su controlador este registrado an la app'));
  config.baseURL = baseURL.api;
  return config;
}, function (error) {
  return Promise.reject(error);
});

export { AxiosClient };
