import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  areas: []
};

const reducers = {
  getAreas: (state, action) => {
    state.areas = action.payload;
  },
  addArea: (state, action) => {
    state.areas = [action.payload, ...state.areas];
  },
  deleteAreas: (state, action) => {
    const areas = [...state.areas];
    state.areas = [...areas.filter(item => item.id !== action.payload.id)];
  },
  updateAreas: (state, action) => {
    const areas = [...state.areas];
    areas[areas.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.areas = areas;
  }
};

const areasManagementSlice = createSlice({
  name: 'areasManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = areasManagementSlice;

// ACTIONS
export const { getAreas, addArea, deleteAreas, updateAreas } = actions;

// GETTERS
export const selectAreasManagementState = (state) => state.areasManagement;

export default reducer;
