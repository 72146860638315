import { SocketContext, socket } from './context/websocket-context';
// import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addMemorandum } from 'store/memorandum/memorandums-slice';
import { updateShifts } from 'store/shifts/shifts-slice';

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    socket.on('new_message', handleMessage);
  }, [socket]);

  const handleMessage = payload => {
    const payloadLoad = JSON.parse(payload);
    setTimeout(() => {
      // toast.info('🦄 ' + payloadLoad.message, {
      //   position: 'bottom-right',
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   draggable: false,
      //   progress: undefined,
      //   theme: 'light'
      // });
      // toast.info(payloadLoad.message);
      loadData(payloadLoad);
    }, 50);
  };

  const loadData = payload => {
    switch (payload.model) {
      case 'shift':
        dispatch(updateShifts(payload.data));
        break;
      case 'round':
      case 'memorandum':
        dispatch(addMemorandum(payload.data));
        break;
      default:
        console.log('Sorry, we are out of model');
    }
  };

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
