import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  permissions: []
};

const reducers = {
  setPermissions: (state, action) => {
    state.permissions = action.payload;
  },
  addPermission: (state, action) => {
    state.permissions = [action.payload, ...state.permissions];
  },
  deletePermission: (state, action) => {
    const permissions = [...state.permissions];
    state.permissions = [...permissions.filter(item => item.id !== action.payload.id)];
  },
  updatePermission: (state, action) => {
    const permissions = [...state.permissions];
    permissions[permissions.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.permissions = permissions;
  }
};

const permissionsManagementSlice = createSlice({
  name: 'permissionsManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = permissionsManagementSlice;

// ACTIONS
export const {
  setPermissions,
  addPermission,
  deletePermission,
  updatePermission
} = actions;

// GETTERS
export const selectPermissionsManagementState = (state) => state.permissionsManagement;

export default reducer;
