import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { EXCEL_EXTENSION, EXCEL_TYPE } from './constans';
import { toast } from 'react-toastify';
import moment from 'moment';

export function exportExcel (shifts, title = 'svg') {
  try {
    if (shifts == null) return false;
    const worksheet = XLSX.utils.json_to_sheet(shifts, { cellStyles: true });
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, title);
  } catch (error) {
    toast.error('Error al descargar el archivo, por favor vuelve a intentar.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
}

export function saveAsExcelFile (buffer, fileName) {
  const data = new Blob([buffer], {
    type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
}

export function showRowAlert (row) {
  const { timeInit, timeExec, check_in, check_out } = row;
  let markAsLate = false;

  if (check_in == null || check_out == null) {
    const currentTime = new Date().getTime();
    const startHour = new Date(`${moment().format('YYYY-MM-DD')} ${timeInit}`);
    const lastHour = new Date(new Date(startHour).getTime() + (1000 * 60 * 60 * Math.round(timeExec)));
    markAsLate = (
      (check_in == null && startHour.getTime() < currentTime) || (check_out == null && lastHour.getTime() < currentTime)
    );
  } else {
    markAsLate = true;
  }

  return {
    'custom-row-danger': markAsLate
  };
}
