import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  memorandums: []
};

const reducers = {
  setMemorandums: (state, action) => {
    state.memorandums = action.payload;
  },
  addMemorandum: (state, action) => {
    state.memorandums = [action.payload, ...state.memorandums];
  },
  removeMemorandum: (state, action) => {
    const memorandums = [...state.memorandums];
    state.memorandums = [...memorandums.filter(item => item.id !== action.payload.memorandum_id)];
  },
  updateMemorandum: (state, action) => {

  }
};

const memorandumsManagementSlice = createSlice({
  name: 'memorandumsManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = memorandumsManagementSlice;

// ACTIONS
export const {
  setMemorandums,
  addMemorandum,
  removeMemorandum,
  updateMemorandum
} = actions;

// GETTERS
export const selectMemorandumsManagementState = (state) => state.memorandumsManagement;

export default reducer;
