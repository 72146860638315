import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employees: [],
  statusConditionalGroups: []
};

const reducers = {
  setEmployees: (state, action) => {
    state.employees = action.payload;
    state.statusConditionalGroups = getStatusConditionalGroups(state.employees);
  },
  addEmployee: (state, action) => {
    action.payload.full_name = `${action.payload.name} ${action.payload.first_name} ${action.payload.last_name}`;
    state.employees = [...state.employees, action.payload];
    state.statusConditionalGroups = getStatusConditionalGroups(state.employees);
  },
  deleteEmployees: (state, action) => {
    const employees = [...state.employees];
    state.employees = [...employees.filter(item => item.id !== action.payload.id)];
  },
  updateEmployees: (state, action) => {
    const employees = [...state.employees];
    const index = employees.findIndex(item => item.id === action.payload.id);
    employees[index] = action.payload;
    state.employees = employees;
    state.statusConditionalGroups = getStatusConditionalGroups(state.employees);
  }
};

// STATUS DYNAMIC GROUPS

const statesDynamics = [
  { name: 'Compañia', value: 'company_id', type: 'field' },
  { name: 'Perfiles', value: 'profiles', type: 'array' },
  { name: 'Sucursal', value: 'branch_id', type: 'field' },
  { name: 'Departamento', value: 'department_id', type: 'field' },
  { name: 'Puesto de trabajo', value: 'job_id', type: 'field' },
  { name: 'Canal', value: 'channel', type: 'field' }
];

const getStatusConditionalGroups = (employees) => {
  let st = [{
    columnName: 'global',
    label: 'Global',
    items: [
      { name: 'Definido', value: 'define' },
      { name: 'indefinido', value: 'undefine' }
    ]
  }];
  statesDynamics.forEach(state => {
    const newState = {
      columnName: state.value,
      label: state.name,
      items: []
    };
    const elementArray = [];
    employees.forEach(element => {
      if (state.type === 'field') {
        const propertyValue = element[state.value];

        if (!elementArray.includes(propertyValue)) {
          newState.items.push({ name: propertyValue, value: propertyValue });
          elementArray.push(propertyValue);
        }
      } else {
        const values = element[state.value];
        values.forEach(item => {
          if (!elementArray.includes(item.name)) {
            newState.items.push({ name: item.name, value: item.name });
            elementArray.push(item.name);
          }
        });
      }
    });

    st = [...st, newState];
  });
  return st;
};

const employeesManagementSlice = createSlice({
  name: 'employeesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = employeesManagementSlice;

// ACTIONS
export const {
  setEmployees,
  addEmployee,
  deleteEmployees,
  updateEmployees
} = actions;

// GETTERS
export const selectEmployeesManagementState = (state) => state.employeesManagement;

export default reducer;
