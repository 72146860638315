import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  places: []
};

const reducers = {
  getPlaces: (state, action) => {
    state.places = action.payload;
  },
  deletePlaces: (state, action) => {
    const places = [...state.places];
    state.places = [...places.filter(item => item.id !== action.payload.id)];
  },
  updatePlaces: (state, action) => {
    const places = [...state.places];
    places[places.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.places = places;
  }
};

const placesManagementSlice = createSlice({
  name: 'placesManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = placesManagementSlice;

// ACTIONS
export const { getPlaces, updatePlaces, deletePlaces } = actions;

// GETTERS
export const selectPlacesManagementState = (state) => state.placesManagement;

export default reducer;
