import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contracts: []
};

const reducers = {
  getContracts: (state, action) => {
    state.contracts = action.payload;
  },
  deleteContracts: (state, action) => {
    const contracts = [...state.contracts];
    state.contracts = [...contracts.filter(item => item.id !== action.payload.id)];
  },
  updateContracts: (state, action) => {
    const contracts = [...state.contracts];
    contracts[contracts.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.contracts = contracts;
  }
};

const contractsManagementSlice = createSlice({
  name: 'contractsManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = contractsManagementSlice;

// ACTIONS
export const { getContracts, deleteContracts, updateContracts } = actions;

// GETTERS
export const selectContractsManagementState = (state) => state.contractsManagement;

export default reducer;
