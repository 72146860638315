import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emails: []
};

const reducers = {
  getEmails: (state, action) => {
    state.emails = action.payload;
  },
  addEmail: (state, action) => {
    state.emails = [action.payload, ...state.emails];
  },
  deleteEmails: (state, action) => {
    const emails = [...state.emails];
    state.emails = [...emails.filter(item => item.id !== action.payload.id)];
  },
  updateEmails: (state, action) => {
    const emails = [...state.emails];
    emails[emails.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.emails = emails;
  }
};

const emailsManagementSlice = createSlice({
  name: 'emailsManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = emailsManagementSlice;

// ACTIONS
export const { getEmails, addEmail, deleteEmails, updateEmails } = actions;

// GETTERS
export const selectEmailsManagementState = (state) => state.emailsManagement;

export default reducer;
