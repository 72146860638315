import React, { createContext, useState } from 'react';

const NotificationContext = createContext();
export const NotificationProvider = ({ children }) => {
  const [notificationsShow, setNotificationsShow] = useState(false);
  const [notificationsTopics, setNotificationsTopics] = useState([]);

  const notificationsToggle = (topics) => {
    setNotificationsTopics(topics);
    setNotificationsShow(!notificationsShow);
  };
  const data = { notificationsTopics, notificationsShow, notificationsToggle };
  return (
		<NotificationContext.Provider value={data}>
			{children}
		</NotificationContext.Provider>
  );
};

export default NotificationContext;
