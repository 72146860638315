export const menu = [{
  label: 'Home',
  items: [
    {
      label: 'Monitoreo de Turnos',
      icon: 'pi pi-fw pi-calendar-plus',
      to: '/dashboard/shifts',
      module: 'shifts'
    },
    {
      label: 'Control de Rondas',
      icon: 'pi pi-fw pi-sitemap',
      to: '/dashboard/rounds',
      module: 'rounds'
    },
    {
      label: 'Minuta Virtual',
      icon: 'pi pi-fw pi-clock',
      to: '/dashboard/memorandums',
      module: 'memorandums'
    },
    {
      label: 'Gestion de Usuarios',
      icon: 'pi pi-fw pi-users',
      to: '/dashboard/users',
      module: 'users'
    },
    {
      label: 'Formularios',
      icon: 'pi pi-fw pi-list',
      to: '/dashboard/forms',
      module: 'forms'
    },
    // {
    //   label: 'Contratos',
    //   icon: 'pi pi-fw pi-shopping-cart',
    //   to: '/dashboard/services',
    //   module: 'contracts'
    // },
    {
      label: 'Gestión de Links',
      icon: 'pi pi-fw pi-link',
      to: '/dashboard/resources',
      module: 'resources'
    }
  ],
  adminMenu: [
    // {
    //   label: 'Lugares',
    //   icon: 'pi pi-fw pi-map',
    //   to: '/dashboard/locations',
    //   module: 'security'
    // },
    {
      label: 'Novedades',
      icon: 'pi pi-fw pi-folder',
      to: '/dashboard/novelties',
      module: 'novelties'
    },
    {
      label: 'Seguridad',
      icon: 'pi pi-fw pi-key',
      to: '/dashboard/security',
      module: 'security'
    },
    // {
    //   label: 'Servicios',
    //   icon: 'pi pi-fw pi-briefcase',
    //   to: '/dashboard/types',
    //   module: 'services'
    // },
    // {
    //   label: 'Compañia',
    //   icon: 'pi pi-fw pi-building',
    //   to: '/dashboard/companies',
    //   module: 'security'
    // },
    {
      label: 'Logout',
      icon: 'pi pi-fw pi-sign-out',
      to: '/login',
      module: ''
    }
  ]
}];

export default menu;
