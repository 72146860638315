import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rounds: [],
  scannedPoints: []
};

const reducers = {
  getRounds: (state, action) => {
    state.rounds = action.payload;
  },
  getScannedPoints: (state, action) => {
    state.scannedPoints = action.payload;
  },
  addRound: (state, action) => {
    const index = state.scannedPoints.findIndex(resp => resp.shift_id === action.payload.shift_id);
    state.scannedPoints[index].points.push(action.payload);
  }
};

const roundsManagementSlice = createSlice({
  name: 'roundsManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = roundsManagementSlice;

// ACTIONS
export const { getRounds, getScannedPoints, addRound } = actions;

// GETTERS
export const selectRoundsManagementState = (state) => state.roundsManagement;

export default reducer;
