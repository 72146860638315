import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: []
};

const reducers = {
  setLists: (state, action) => {
    state.lists = action.payload;
  },
  addList: (state, action) => {
    state.lists = [...state.lists, action.payload];
  },
  deleteLists: (state, action) => {
    const lists = [...state.lists];
    state.lists = [...lists.filter(item => item.id !== action.payload.id)];
  },
  updateList: (state, action) => {
    const updatedItem = action.payload;
    const lists = [...state.lists];
    state.lists = lists.map((item) => {
      if (item.id === updatedItem.id) return updatedItem;
      return item;
    });
  }
};

const listsManagementSlice = createSlice({
  name: 'listsManagement',
  initialState,
  reducers
});

// ACTIONS
export const { setLists, addList, deleteLists, updateList } = listsManagementSlice.actions;

// GETTERS
export const selectListsManagementState = (state) => state.listsManagement;

export default listsManagementSlice.reducer;
