import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';

const normalizeText = css`
	color: ${prop('color', prop('theme.colors.gray90'))};
	margin: ${prop('margin')};
	padding: ${prop('padding')};
	text-align: ${prop('textAlign')};
	font-weight: ${prop('fontWeight')};
	font-family: sans-serif;
	${(props) => props.textTransform && `text-transform: ${props.textTransform}`};

`;

export const H1 = styled.h1`
	${normalizeText};
	font-style: normal;
	font-size: ${prop('fontSize', '3rem')};
	line-height: ${prop('lineHeight', '4rem')};
	text-align:center;

`;

H1.defaultProps = {
  responsive: true,
  fontWeight: '600'
};

export const H2 = styled.h2`
	${normalizeText};
	font-style: normal;
	font-size: ${prop('fontSize', '24rem')};
	line-height: ${prop('lineHeight', '36rem')};

`;

H2.defaultProps = {
  responsive: true,
  fontWeight: '600'
};

export const H3 = styled.h3`
	${normalizeText};
	font-style: normal;
	font-size: ${prop('fontSize', '20rem')};
	line-height: ${prop('lineHeight', '30rem')};

`;

H3.defaultProps = {
  responsive: true,
  fontWeight: '500'
};

export const Subtitle1 = styled.p`
	${normalizeText};
	font-style: normal;
	font-size: ${prop('fontSize', '18rem')};
	line-height: ${prop('lineHeight', '27rem;')};

`;

Subtitle1.defaultProps = {
  responsive: true,
  fontWeight: '500'
};

export const Subtitle2 = styled.p`
	${normalizeText};
	font-style: normal;
	font-size: ${prop('fontSize', '16rem')};
	line-height: ${prop('lineHeight', '24rem;')};

`;

Subtitle2.defaultProps = {
  responsive: true,
  fontWeight: '500'
};

export const Body1 = styled.p`
	${normalizeText};
	font-style: normal;
	font-weight: ${prop('fontWeight', 'normal')};
	font-size: ${prop('fontSize', '16rem')};
	line-height: ${prop('lineHeight', '24rem;')};

`;

Body1.defaultProps = {
  responsive: true
};

export const Body2 = styled.p`
	${normalizeText};
	font-style: normal;
	font-weight: normal;
	font-size: ${prop('fontSize', '14rem')};
	line-height: ${prop('lineHeight', '21rem;')};

`;

Body2.defaultProps = {
  responsive: true
};

export const Caption1 = styled.p`
	${normalizeText};
	font-style: normal;
	font-weight: 500;
	font-size: ${prop('fontSize', '14rem')};
	line-height: ${prop('lineHeight', '21rem;')};

`;

export const Caption2 = styled.p`
	${normalizeText};
	font-style: normal;
	font-weight: normal;
	font-size: ${prop('fontSize', '12rem')};
	line-height: ${prop('lineHeight', '18rem;')};

`;

Caption2.defaultProps = {
  responsive: true
};

export const Overline = styled.p`
	${normalizeText};
	font-style: normal;
	font-weight: normal;
	font-size: ${prop('fontSize', '10rem')};
	line-height: ${prop('lineHeight', '15rem;')};

`;

Overline.defaultProps = {
  responsive: true
};
