import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modules: []
};

const reducers = {
  setModules: (state, action) => {
    state.modules = action.payload;
  },
  addModule: (state, action) => {
    state.modules = [action.payload, ...state.modules];
  },
  deleteModule: (state, action) => {
    const modules = [...state.modules];
    state.modules = [...modules.filter(modulo => modulo.id !== action.payload.id)];
  },
  updateModule: (state, action) => {
    const modules = [...state.modules];
    modules[modules.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.modules = modules;
  }
};

const modulesManagementSlice = createSlice({
  name: 'modulesManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = modulesManagementSlice;

// ACTIONS
export const {
  setModules,
  addModule,
  deleteModule,
  updateModule
} = actions;

// GETTERS
export const selectModulesManagementState = (state) => state.modulesManagement;

export default reducer;
