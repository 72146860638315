import { useState, useRef } from 'react';
// import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
// import { Button } from 'primereact/button';
import './svg-filter-odoo.scss';

function isEmpty (str) {
  return (!str || str.length === 0);
}

export function SvgFilterOdoo (props) {
  const menu = useRef(null);
  const { parametersFilter, defaultParameterFilter, onFilterSignals, filterDefault, filterTimeStamp = [], onRemovedItem = e => { } } = props;

  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterList, setFilterListText] = useState(filterDefault);
  const changeTextFilter = (event) => {
    setFilterText(event.target.value);
    setOpen(true);
    menu.current.show(event);
  };

  const UpdateFilterList = (itemFilter) => {
    if (!isEmpty(filterText)) {
      if (filterList.filter(item => item.categoryValue === itemFilter.categoryValue).length > 0) {
        const newFilterList = filterList.map((item) => {
          if (item.categoryValue === itemFilter.categoryValue) {
            item.display = item.display + ' o ' + filterText;
            item.values.push(filterText);
          }
          return item;
        });
        setFilterListText(newFilterList);
        onFilterSignals(newFilterList);
      } else {
        const newFilterList = [...filterList, { categoryValue: itemFilter.categoryValue, categoryDisplay: itemFilter.categoryDisplay, display: filterText, values: [filterText] }];
        setFilterListText(newFilterList);
        onFilterSignals(newFilterList);
      }

      setFilterText('');
      setOpen(false);
      menu.current.hide('ocultar');
    }
  };

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter' || event.key === 33) {
      if (parametersFilter.length > 0) { UpdateFilterList(defaultParameterFilter || parametersFilter[0]); }
    }
  };
  const handleAddFiler = (itemFilter) => {
    UpdateFilterList(itemFilter);
  };
  const handleRemoveFilter = (filterRemove, event) => {
    if (filterRemove.comparer) {
      onRemovedItem(filterRemove);
    } else {
      const newFilterList = filterList.filter(item => item.categoryValue !== filterRemove.categoryValue);
      setFilterListText(newFilterList);
      onRemovedItem(filterRemove);
      onFilterSignals(newFilterList);
    }
  };

  // const onSelectedMenu = (arg) => {
  //   handleAddFiler(arg);
  // };

  const onBlur = () => {
    setTimeout(() => {
      setOpen(false);
      menu.current.hide('ocultar');
    }, 1000);
  };

  const getItems = () => {
    let it = [];
    it = parametersFilter.map((item) => (
      {
        label: <>
          Buscar en <strong>{item.categoryDisplay}</strong> el valor:  <span className="text-blue-500">{filterText}</span>
        </>,
        command: () => { handleAddFiler(item); }
      }
    ));

    return it;
  };

  return (
    <div>
      <label htmlFor="search_field" className="sr-only">
        Search
      </label>
      <div className="flex flex-wrap space-x-1 border-2 border-light-blue-500 border-opacity-50 bg-white p-2 rounded-lg ">
        {
          [...filterTimeStamp, ...filterList].map((item, key) => {
            return (<div key={key} className="flex flex-row">
              <div className="svg-filter-odoo-category pl-1 pr-1 bg-gray-500 text-purple-50 p-px sm:text-sm center">{item.categoryDisplay}</div>
              <div className="pl-1 pr-1 bg-purple-100 p-px sm:text-sm center">{item.display}</div>
              {(!item.disableClose) && <button
                onClick={(event) => handleRemoveFilter(item, event)}
                key={key}
                className="inline-flex items-center px-1 py-1 border border-transparent text-xs font-medium text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none "
              >X
              </button>}
            </div>);
          })
        }
        {/* <i className='pi pi-search' /> */}
        <input
          id="search_field"
          className="svg-filter-odoo-input"
          placeholder="Buscar"
          type="search"
          name="Buscar"
          value={filterText}
          onChange={changeTextFilter}
          onBlur={onBlur}
          onKeyUp={handleOnKeyUp}
        />
      </div>

      <div>
        <Menu model={getItems()} popup ref={menu} className="svg-filter-odoo-menu" />
      </div>
    </div>
  );
}
