import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: []
};

const reducers = {
  getCategories: (state, action) => {
    state.categories = action.payload;
  },
  deleteCategories: (state, action) => {
    const categories = [...state.categories];
    state.categoriess = [...categories.filter(item => item.id !== action.payload.id)];
  },
  updateCategories: (state, action) => {
    const categories = [...state.categories];
    categories[categories.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.categories = categories;
  }
};

const categoriesManagementSlice = createSlice({
  name: 'categoriesManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = categoriesManagementSlice;

// ACTIONS
export const { getCategories, deleteCategories, updateCategories } = actions;

// GETTERS
export const selectCategoriesManagementState = (state) => state.categoriesManagement;

export default reducer;
