// EXCEL
export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = '.xlsx';

export const columns = [
  { label: 'Puesto', value: 'Puesto' },
  { label: 'Empleado', value: 'employee' },
  { label: 'Fecha y hora de inicio', value: 'Fecha y hora de inicio' },
  { label: 'Fecha y hora de salida', value: 'Fecha y hora de salida' },
  { label: 'Check in', value: 'Check in' },
  { label: 'Check out', value: 'Check out' },
  { label: 'Estado check in', value: 'Estado check in' },
  { label: 'Editado por', value: 'Editado por' },
  { label: 'Reportes', value: 'Reportes' }
];
