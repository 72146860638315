import { AxiosClient } from 'config';
import { toast } from 'react-toastify';

export const createMessageService = async (payload) => {
  try {
    await AxiosClient.post(
      'users/account/message',
      payload
    );
    toast.success('Notificación enviada con éxito.');
  } catch (error) {
    const errorMsg = error?.response?.data?.error ?? 'Error al enviar la notificación, por favor, intenta de nuevo.';
    toast.error(errorMsg);
  }
};
