import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groups: []
};

const reducers = {
  setGroups: (state, action) => {
    state.groups = action.payload;
  },
  addGroup: (state, action) => {
    state.groups = [action.payload, ...state.groups];
  },
  deleteGroup: (state, action) => {
    const groups = [...state.groups];
    state.groups = [...groups.filter(modulo => modulo.id !== action.payload.id)];
  },
  updateGroup: (state, action) => {
    const groups = [...state.groups];
    groups[groups.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.groups = groups;
  }
};

const groupsManagementSlice = createSlice({
  name: 'groupsManagement',
  initialState,
  reducers
});

const {
  actions,
  reducer
} = groupsManagementSlice;

// ACTIONS
export const {
  setGroups,
  addGroup,
  deleteGroup,
  updateGroup
} = actions;

// GETTERS
export const selectGroupsManagementState = (state) => state.groupsManagement;

export default reducer;
