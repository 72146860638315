import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companies: []
};

const reducers = {
  getCompanies: (state, action) => {
    state.companies = action.payload;
  },
  addCompany: (state, action) => {
    state.companies = [action.payload, ...state.companies];
  },
  deleteCompanies: (state, action) => {
    const companies = [...state.companies];
    state.companies = [...companies.filter(item => item.id !== action.payload.id)];
  },
  updateCompanies: (state, action) => {
    const companies = [...state.companies];
    companies[companies.findIndex(item => item.id === action.payload.id)] = action.payload;
    state.companies = companies;
  }
};

const companiesManagementSlice = createSlice({
  name: 'companiesManagement', // This must be unique
  initialState,
  reducers
});

const { actions, reducer } = companiesManagementSlice;

// ACTIONS
export const { getCompanies, addCompany, deleteCompanies, updateCompanies } = actions;

// GETTERS
export const selectCompaniesManagementState = (state) => state.companiesManagement;

export default reducer;
